<template>
  <div>
    <template v-if="$auth.can('analyticpoint','usuario_votador')">
      <div class="row my-3">
        <h1 class="col-sm-12 text-right">Analytic Point</h1>
      </div>

      <div class="d-flex flex-wrap">
        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Solicitudes</h4>
              <p class="card-subtitle text-muted">Administración de solicitudes</p>
              <router-link :to="{name: 'analyticpoint-solicitudes'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Histórico</h4>
              <p class="card-subtitle text-muted">Administración de Histórico</p>
              <router-link :to="{name: 'analyticpoint-historico'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="($auth.can('analyticpoint','usuario_cesion') || $auth.can('analyticpoint','usuario_cesion_admin'))">
      <div class="row">
        <h2 class="col-sm-12 text-right">Cesiones</h2>
      </div>

      <div class="d-flex flex-wrap">
        <div v-if="$auth.can('analyticpoint','usuario_cesion_admin')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Solicitudes</h4>
              <p class="card-subtitle text-muted">Cesiones</p>
              <router-link :to="{name: 'analyticpoint-cesiones'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Histórico</h4>
              <p class="card-subtitle text-muted">Histórico de cesiones</p>
              <router-link :to="{name: 'analyticpoint-cesiones-historico'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>

        <div v-if="$auth.can('analyticpoint','usuario_cesion_admin')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Alianzas</h4>
              <p class="card-subtitle text-muted">Administración de Alianzas</p>
              <router-link :to="{name: 'analyticpoint-alianzas'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$auth.can('analyticpoint','usuario_admin')">
      <div class="row">
        <h2 class="col-sm-12 text-right">Administración</h2>
      </div>

      <div class="d-flex flex-wrap">
        <div v-if="$auth.can('analyticpoint','comites_listar')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Comité</h4>
              <p class="card-subtitle text-muted">Administración de Comité</p>
              <router-link :to="{name: 'analyticpoint-admin-solicitudes'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>

        <div v-if="$auth.can('analyticpoint','solicitudes_listar')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Histórico</h4>
              <p class="card-subtitle text-muted">Administración de Histórico</p>
              <router-link :to="{name: 'analyticpoint-historico'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>

        <div v-if="$auth.can('analyticpoint','config_crear')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Configuración</h4>
              <p class="card-subtitle text-muted">Administración de Configuración</p>
              <router-link :to="{name: 'analyticpoint-admin-configuracion'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>

        <div v-if="$auth.can('analyticpoint','acreditadores_crear')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Acreditadores</h4>
              <p class="card-subtitle text-muted">Administración de Acreditadores</p>
              <router-link :to="{name: 'analyticpoint-admin-acreditadores'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>

        <div v-if="$auth.can('analyticpoint','catalogos_crear')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <h4 class="card-title">Catálogos</h4>
              <p class="card-subtitle text-muted">Administración de Catálogos</p>
              <router-link :to="{name: 'analyticpoint-admin-catalogos'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

  export default {
    mounted() {
      let menus = [];

      if (this.$auth.can('analyticpoint','usuario_votador')) {
        let menu = {title:'Comité'};

        if (this.$auth.can('analyticpoint','solicitudes_listar'))
          menu['analyticpoint-solicitudes'] = 'Solicitudes';

        if (this.$auth.can('analyticpoint','solicitudes_listar'))
          menu['analyticpoint-historico'] = 'Histórico';
       
        menus.push(menu);
      }

      if (this.$auth.can('analyticpoint','usuario_cesion') || this.$auth.can('analyticpoint','usuario_cesion_admin')) {
        let cesiones = {title: 'Cesiones'};

      if (this.$auth.can('analyticpoint','cesiones_listar') && this.$auth.can('analyticpoint','usuario_cesion_admin'))
        cesiones['analyticpoint-cesiones'] = 'Solicitudes';

      if (this.$auth.can('analyticpoint','cesiones_listar'))
        cesiones['analyticpoint-cesiones-historico'] = 'Histórico';

        if (this.$auth.can('analyticpoint','usuario_cesion_admin'))
          cesiones['analyticpoint-alianzas'] = 'Alianzas';

        menus.push(cesiones);
      }

      if (this.$auth.can('analyticpoint','usuario_admin')) {
        let admin = {title: 'Administración'};

        if (this.$auth.can('analyticpoint','comites_listar'))
          admin['analyticpoint-admin-solicitudes'] = 'Comité';

        if (this.$auth.can('analyticpoint','solicitudes_listar'))
          admin['analyticpoint-historico'] = 'Histórico';

        if (this.$auth.can('analyticpoint','config_crear'))
          admin['analyticpoint-admin-configuracion'] = 'Configuración';

        if (this.$auth.can('analyticpoint','acreditadores_crear'))
          admin['analyticpoint-admin-acreditadores'] = 'Acreditadores';

        if (this.$auth.can('analyticpoint','catalogos_crear'))
          admin['analyticpoint-admin-catalogos'] = 'Catálogos';

        menus.push(admin);
      }

      this.$store.commit('setMenu',menus);
    }
  }
</script>